/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line, no-param-reassign */
import React, { Component } from "react";
import styled from "@emotion/styled";
import { useTable, useSortBy, usePagination } from "react-table";
import { navigate, Link } from "gatsby";
import toast from "../../utils/toast";
import { Box } from "../../style/basicStyle";
import { withFirebase } from "../Firebase";
import { getHiddenColumns, IndeterminateCheckbox } from "../TableFilters/TableFilters";
import { TableStyles, IconInline } from '../../style/tableStyling.js'

function Table({ columns, data, initialState }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    getToggleHideAllColumnsProps,
    allColumns
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: getHiddenColumns(columns),
        pageIndex: 0,
        pageSize: 5,
        sortBy: [
          {
            id: "completionDate",
            desc: true
          },
          {
            id: "invoiceNumber",
            desc: true
          }
        ]
      }
    },
    useSortBy,
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <div>
        <div>
          <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
        </div>
        {/* Loop through columns data to create checkbox */}
        {allColumns.map((column) => (
          <div className="cb action" key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
              <span>{column.Header}</span>
            </label>
          </div>
        ))}
        <br />
      </div>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                {/* eslint-disable-next-line no-nested-ternary */}
                <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
      <Box my={3}>
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        {" "}
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>
        {" "}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
        {" "}
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
        {" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageGoto = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageGoto);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20].map((pageSizeNew) => (
            <option key={pageSizeNew} value={pageSizeNew}>
              Show {pageSizeNew}
            </option>
          ))}
        </select>
      </Box>
    </>
  );
}

const orderRecord = (order) => {
  return {
    invoiceNumber: order.invoiceNumber,
    status: order.status,
    grandTotal: order.grandTotal,
    trackingNumber: order.trackingNumber,
    completionDate: order.completionDate,
    docId: order.docId,
    itemsCount: order.itemsCount
  };
};

const columns = [
  {
    Header: "Orders",
    columns: [
      {
        Header: "Invoice #",
        accessor: "invoiceNumber"
      },
      {
        Header: "Status",
        accessor: "status"
      },
      {
        Header: "Tracking #",
        accessor: "trackingNumber",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          let { trackingNumber } = original;
          try {
            const { trackingUrl } = original;
            trackingNumber = trackingUrl && (
              <a rel="noreferrer" target="_blank" href={trackingUrl}>
                {trackingNumber}
              </a>
            );
          } catch (e) {
            console.log("CELL error: ", e);
          }
          return trackingNumber || original.trackingNumber;
        }
      },
      {
        Header: "QTY",
        accessor: "itemsCount",
        disableSortBy: true
      },
      {
        Header: "Total",
        accessor: "grandTotal",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          const { grandTotal } = original;
          return (grandTotal && `$ ${grandTotal}`) || null;
        }
      },
      {
        Header: "Date",
        accessor: "completionDate",
        Cell: ({ row: { original } }) => {
          let { completionDate } = original;
          try {
            const completionDateArray = completionDate.split("T")[0].split("-");
            completionDate = `${completionDateArray[1]}/${completionDateArray[2]}/${completionDateArray[0]}`;
          } catch (e) {
          } // we dont really care about errors here...
          return completionDate;
        }
      },
      {
        Header: "Action",
        accessor: "docId",
        disableSortBy: true,
        Cell: ({ row: { original } }) => {
          const { docId } = original;
          const action = docId && (
            <Link target="_blank" to={`/viewOrder/${docId}`}>
              View Order
            </Link>
          );
          return action || null;
        }
      }
    ]
  }
];

class Orders extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      idToken: undefined,
      loading: false,
      OrdersList: []
    };
  }

  componentDidMount() {
    this.getOrdersList();
  }

  componentDidUpdate() {
    this.getOrdersList();
  }

  getOrdersList = () => {
    if (this.props.firebase && !this._initFirebase) {
      const self = this;
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.props.firebase.auth.currentUser
            // eslint-disable-next-line react/prop-types
            .getIdToken(/* forceRefresh */ true)
            .then(function getIdToken(idToken) {
              const values = { idToken };
              fetch("/.netlify/functions/getOrders", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(values)
              })
                .then((result) => {
                  try {
                    const resultJSON = result.json();
                    resultJSON
                      .then(function processResult(r) {
                        if (result.status === 200) {
                          self.setState({
                            // eslint-disable-next-line react/no-unused-state
                            OrdersList: r.message,
                            loading: false,
                            idToken
                          });
                        } else {
                          console.log("order request ERROR 1 - submitted values: ", values, " | error is: ", r);
                          toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                        }
                      })
                      .catch((error) => {
                        console.log("order request ERROR 2 - submitted values: ", values, " | error is: ", error);
                        toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
                      });
                  } catch (e) {
                    console.log("order request ERROR 22 - submitted values: ", values, " | res is: ", result, " | error is: ", e);
                    toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                })
                .catch((error) => {
                  console.log("order request ERROR 3 - submitted values: ", values, " | error is: ", error);
                  toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
                });
            })
            .catch(function getIdTokenError(error) {
              console.log("order request - getIdTokenError - error is: ", error);
            });
        }
      });
    }
  };

  formatTable() {
    const { state } = this;
    const { idToken } = state;
    const { OrdersList } = state;
    const length = OrdersList && OrdersList.length;
    if (length) {
      // console.log('OrdersList: ', OrdersList);
      const formatTableLevel = (depth = 0) => {
        return OrdersList.map((order) => {
          return {
            ...orderRecord(order, idToken),
            subRows: length[depth + 1] ? formatTableLevel(depth + 1) : undefined
          };
        });
      };
      return formatTableLevel();
    }
    return [];
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        {loading && <div>Loading ...</div>}
        <TableStyles>
          <Table
            columns={columns}
            data={this.formatTable()}
          />
        </TableStyles>
      </>
    );
  }
}

export default withFirebase(Orders);
