/* eslint-disable no-undef, react/prop-types */
import React, { Component } from 'react';
import { compose } from 'recompose';
import { graphql, navigate } from 'gatsby';
import LayoutWithAuth from '../components/layoutWithAuth';
import { withAuthorization, withEmailVerification } from '../components/Session';
import { Orders } from '../components/Orders';
import { Box, Container, Flex } from '../style/basicStyle';

const OrdersPageBase = () => {
  return (
    <Box px={[2, 3, 5]}>
      <h3>Orders</h3>
      <Orders />
    </Box>
  );
};

const condition = (authUser) => authUser;

const OrdersPage = compose(withEmailVerification, withAuthorization(condition))(OrdersPageBase);

const Named = ({ toggleNavDark, location }) => {
  return (
    <Box py={[5]}>
      <LayoutWithAuth navDark toggleNavDark={toggleNavDark}>
        <OrdersPage />
      </LayoutWithAuth>
    </Box>
  );
};

export default Named;

export const pageQuery = graphql`
  query OrdersContentQuery {
    pageData: allPageDataJson(filter: { name: { eq: "Orders" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
